import { useEffect, useState } from 'react';

import cn from '../../../../lib/classNames';
import { ChartTypeType } from '../StatisticsDistributionViCRI';

import styles from './ComparisonInfo.module.scss';

export type ComparisonInfoType = {
	type?: ChartTypeType;
	percent: number;
	min: number;
	max: number;
};

export default function ComparisonInfo(props: ComparisonInfoType) {
	const { type, percent, min, max } = props;
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(true);
	}, []);

	const body = `Destination country ${type} is higher than ${percent}% of indexed nations`;

	return (
		<div className={cn(styles['comparison-info'], { [styles.visible]: show })}>
			<h5 className={styles.title}>
				<div className={cn(styles.image, 'material-icons-round')}>location_on</div>
				<div className={styles.text}>{`${type} ${min}-${max}`}</div>
			</h5>
			<p className={styles.body}>{body}</p>
		</div>
	);
}
