// TODO: need to remove the compopent after cleaning reports history

import { ReactNode } from 'react';

import cn from '../../../lib/classNames';

import Info from './Info';

import styles from './HeaderMarkdown.module.scss';

type HeaderMarkdownType = {
	className?: string;
	descriptionMain: ReactNode;
	info: ReactNode;
};

export default function HeaderMarkdown(props: HeaderMarkdownType) {
	const { className, descriptionMain, info } = props;

	return (
		<>
			<section className={cn(styles.bluf, className)}>
				<div className={styles.header}>
					<div className={styles.description}>{descriptionMain}</div>
				</div>
			</section>
			<section className={cn(styles.body, className)}>
				<Info description={info} title="" />
			</section>
		</>
	);
}
