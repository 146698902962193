import { useCallback, useContext, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { TravellerInfo } from './components';
import { useContextNavigation } from '../../components/ContextNavigation';

import { ApiReportType, CovidType, FromToDetailsType } from '../../api/types/apiGetReport';
import { ROUTE } from '../../App';
import { Context, GoogleReCaptcha } from '../../components';
import { isEmptyObj } from '../../lib';
import cn from '../../lib/classNames';
import { api } from '../../sagas';
import Layout from '../Layout/LayoutDashboard';
import { PAGES } from '../pagesNavigation';

import ReportPreloader from './ReportPreloader/ReportPreloader';
import TravelRiskReport from './TravelRiskReport';

import styles from './Report.module.scss';

type RouteParamsType = {
	uuid: string;
};

type DataRequestType = {
	covid: CovidType;
	fromToDetails: FromToDetailsType;
};

export default function Report() {
	const { uuid } = useParams<RouteParamsType>();
	const [ready, setReady] = useState(false);
	const [error, setError] = useState(false);
	const [reportError, setReportError] = useState(false);
	const [reportData, setReportData] = useState({} as ApiReportType);

	const {
		googleToken,
		setGoogleToken,
		isLogged,
		userProfileData,
		setHomeRequest,
		setDestinationRequest,
	} = useContext(Context);

	const { activePage } = useContextNavigation();

	const { gender, name, ageBucket, seName } = userProfileData;

	const preloaderData = {
		home: name,
		personName: seName,
		personAgeBucket: ageBucket,
		personGender: gender,
	};

	const reportDataFromToDetails = reportData?.fromToDetails;
	const aiReport = reportData?.aiReport;

	const handleCaptchaVerify = useCallback((newToken: string) => {
		setGoogleToken(newToken);
	}, []);

	const isHomeData = isLogged && name !== '' && gender !== '' && ageBucket !== '';

	const updateDataToStore = (data: DataRequestType) => {
		setHomeRequest({
			ageBucket: data.fromToDetails.personAgeBucket,
			gender: data.fromToDetails.personGender,
			location: {
				label: data.fromToDetails.home,
				value: {
					latitude: data.covid.home.covidLat,
					longitude: data.covid.home.covidLng,
					name: data.fromToDetails.home,
				},
			},
		});

		setDestinationRequest({
			label: data.covid.destination.covidLocation,
			value: {
				latitude: data.covid.destination.covidLat,
				longitude: data.covid.destination.covidLng,
				name: data.covid.destination.covidLocation,
			},
		});
	};

	if (uuid && !ready && googleToken && !reportError) {
		if (isHomeData && !isEmptyObj(reportData)) {
			setReady(true);
		} else {
			api.getHomeDestinationReportHistory(uuid).then((response) => {
				if (response.ok) {
					if (!isHomeData) {
						updateDataToStore(response.data);
					}

					setReportData(response.data);
					setReady(true);
				} else {
					setReportError(true);
					setError(true);
				}
			});
		}
	} else if (!uuid) {
		return <Redirect to={ROUTE.default} />;
	}

	if (error) {
		return <Redirect to={ROUTE.default} />;
	}
	if (reportError) {
		return (
			<div className={styles['report-container']}>
				<div>Sorry, but this URL is expired.</div>
				<div>
					<Link to="/">Click here</Link> to return the COVID-19 Personal Risk Explorer and perform a
					new comparison with current data.
				</div>
			</div>
		);
	}

	return (
		<>
			{!googleToken && <GoogleReCaptcha onVerify={handleCaptchaVerify} />}
			<Layout>
				{(activePage.id === PAGES.riskScoring.id || aiReport || aiReport === null) && (
					<div className={styles['traveller-info-container']}>
						<TravellerInfo
							reportId={activePage.id}
							reportTitle={activePage.reportTitle}
							personName={reportDataFromToDetails?.personName}
							personAgeBucket={reportDataFromToDetails?.personAgeBucket}
							personGender={reportDataFromToDetails?.personGender}
							date={reportDataFromToDetails?.date}
							home={reportDataFromToDetails?.home}
							destination={reportDataFromToDetails?.destination}
						/>
					</div>
				)}
				<CSSTransition
					in={ready}
					timeout={100}
					classNames={{
						enterDone: styles['report-container-done-enter'],
					}}
					unmountOnExit
				>
					<div className={styles['report-container']}>
						<TravelRiskReport
							className={styles.report}
							reportData={reportData}
							reportVariant="PE-report"
						/>
					</div>
				</CSSTransition>
				<CSSTransition
					in={!ready}
					appear
					timeout={100}
					classNames={{
						enterDone: styles['report-container-done-enter'],
					}}
					unmountOnExit
				>
					<div className={styles['report-container']}>
						<ReportPreloader className={cn(styles.preloader)} preloaderData={preloaderData} />
					</div>
				</CSSTransition>
			</Layout>
		</>
	);
}
