import { ReactNode, useRef } from 'react';
import { saveAs } from 'file-saver';

import { CovidGeographyItemType } from '../../../api/types/apiGetReport';
import DataLayer from '../../../components/DataLayer';
import Download from '../../../components/Download';
import World from '../../../components/World';
import useMediaQuery from '../../../hooks/MediaQuery';
import cn from '../../../lib/classNames';
import html2canvas from '../../../lib/html2canvas';

import styles from './StatisticsMap.module.scss';

type StatisticsMapType = {
	showDownloadScreenIcon: boolean;
	headerText: ReactNode;
	dataLayer: number;
	countries?: CovidGeographyItemType[];
	home: {
		countryCode?: string;
		covidBucket?: number;
	};
	destination: {
		countryCode?: string;
		covidBucket?: number;
	};
};

type statDataType = {
	statNumOne: string;
	statNumTwo: string;
};

const statData = [
	{
		statNumOne: '0',
		statNumTwo: '0.01',
	},
	{
		statNumOne: '0.01',
		statNumTwo: '1',
	},
	{
		statNumOne: '1',
		statNumTwo: '10',
	},
	{
		statNumOne: '10',
		statNumTwo: '50',
	},
	{
		statNumOne: '50',
		statNumTwo: '100',
	},
	{
		statNumOne: '100',
		statNumTwo: '200',
	},
	{
		statNumOne: '200',
		statNumTwo: '400',
	},
	{
		statNumOne: '400',
		statNumTwo: '600',
	},
	{
		statNumOne: '600',
		statNumTwo: '800',
	},
	{
		statNumOne: '800',
		statNumTwo: '1000+',
	},
];

export default function StatisticsMap(props: StatisticsMapType) {
	const {
		showDownloadScreenIcon = true,
		headerText,
		dataLayer = -1,
		countries,
		home,
		destination,
	} = props;

	const elementToShare = useRef(null);

	const isMobile = useMediaQuery('(max-width: 767px)');

	const handleClickShare = () => {
		html2canvas(elementToShare.current).then((canvas) => {
			saveAs(canvas.toDataURL(), 'statistics-map.png');
		});
	};

	const renderScaleItems = () => {
		const scaleItem = (
			idx: string,
			element: statDataType,
			isRenderHome: boolean,
			isRenderDestination: boolean,
		) => {
			return (
				<div className={styles['scale-item']} key={idx}>
					<div className={styles['box-stat']}>
						<span className={cn(styles.heatmap, `blue-heatmap-${idx}`)} />
						<div>
							<span className={styles['stat-num']}>{element.statNumOne}</span>
							<span className={styles['stat-num-devider']}> - </span>
							<span className={cn(styles['stat-num'], styles['stat-num-two'])}>
								{element.statNumTwo}
							</span>
						</div>
					</div>
					{isRenderHome && <span className={styles['location-title']}>{home.countryCode}</span>}
					{isRenderDestination && (
						<span className={styles['location-title']}>{destination.countryCode}</span>
					)}
				</div>
			);
		};

		const result = statData.map((e, i) => {
			const idx = i + 1 < 10 ? `0${i + 1}` : (i + 1).toString();

			const isRenderHome = i + 1 === home?.covidBucket;
			const isRenderDestination =
				i + 1 === destination?.covidBucket && home?.countryCode !== destination.countryCode;

			if (!isMobile || isRenderHome || isRenderDestination) {
				return scaleItem(idx, e, isRenderHome, isRenderDestination);
			}

			return null;
		});

		return result;
	};

	return (
		<div
			className={cn(styles['statistics-map-container'], 'section-wrap-white')}
			ref={elementToShare}
		>
			{dataLayer >= 0 && (
				<div className="data-layer-container">
					<DataLayer numData={dataLayer} />
				</div>
			)}
			<div className={styles.header}>
				<div>
					<h4>{headerText}</h4>
				</div>
				<div className={styles['block-right']}>
					{showDownloadScreenIcon && (
						<div className="download-icon" onClick={handleClickShare}>
							<Download className="icons-animation" />
						</div>
					)}
				</div>
			</div>
			<div className={styles['box-map']}>
				<div>
					<World items={countries} colorsTheme="blue" variant="map-covid" statData={statData} />
				</div>
				<div className={styles['block-map-scale']}>{renderScaleItems()}</div>
			</div>
		</div>
	);
}
