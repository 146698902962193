import { useRef } from 'react';
import { saveAs } from 'file-saver';

import { TopDeathCovidGeographyItemType } from '../../../api/types/apiGetReport';
import { Download, GraphColor } from '../../../components';
import cn from '../../../lib/classNames';
import html2canvas from '../../../lib/html2canvas';

import styles from './CountriesStatistics.module.scss';

const staticCountriesAbsoluteRanking = [
	{ locationName: 'Mexico', covidDeathBucket: 10 },
	{ locationName: 'India', covidDeathBucket: 10 },
	{ locationName: 'Bolivia', covidDeathBucket: 10 },
	{ locationName: 'Honduras', covidDeathBucket: 10 },
	{ locationName: 'Belgium', covidDeathBucket: 10 },
	{ locationName: 'Pakistan', covidDeathBucket: 9 },
	{ locationName: 'Kazakhstan', covidDeathBucket: 9 },
	{ locationName: 'Kyrgyzstan', covidDeathBucket: 9 },
	{ locationName: 'Australia', covidDeathBucket: 8 },
	{ locationName: 'USA', covidDeathBucket: 8 },
];

type CountriesStatisticsType = {
	showDownloadScreenIcon?: boolean;
	data?: TopDeathCovidGeographyItemType[];
};

export default function CountriesStatistics(props: CountriesStatisticsType) {
	const { showDownloadScreenIcon = true, data } = props;
	const elementToShare = useRef<HTMLDivElement>(null);

	const handleClickShare = () => {
		html2canvas(elementToShare.current).then((canvas) => {
			saveAs(canvas.toDataURL(), 'countries-statistics.png');
		});
	};

	const renderCountriesAbsoluteRanking = () => {
		const topCountriesArr = data?.length ? data : staticCountriesAbsoluteRanking;
		return topCountriesArr.map((e) => {
			return (
				<li className={styles.item} key={e.locationName}>
					<div className={styles['icon-color']}>
						<GraphColor
							className={`blue-heatmap-${
								e.covidDeathBucket > 0 && e.covidDeathBucket < 10
									? `0${e.covidDeathBucket}`
									: e.covidDeathBucket
							}`}
						/>
					</div>
					<div>
						<div className={styles.country}>{e.locationName}</div>
						<div className={styles.ranking}>{e.covidDeathBucket} Absolute Ranking</div>
					</div>
				</li>
			);
		});
	};

	return (
		<div
			ref={elementToShare}
			className={cn(styles['block-countries-statistics'], 'section-wrap-white')}
		>
			<div>
				<div className={styles.header}>
					<div>
						<h4>COVID Top 10</h4>
						<p>Countries with Highest COVID19 Risks</p>
					</div>
					{showDownloadScreenIcon ? (
						<div className="download-icon block-animation" onClick={handleClickShare}>
							<Download className="icons-animation" />
						</div>
					) : null}
				</div>
				<div className={styles.body}>
					<ul>{renderCountriesAbsoluteRanking()}</ul>
				</div>
			</div>
		</div>
	);
}
