import {
	BarChartDataType,
	DistributionItemType,
	DistributionsType,
} from '../../../api/types/apiGetReport';
import { Color } from '../../../theme';

import DistributionBarChart from './DistributionBarChart/DistributionBarChart';

import styles from './StatisticsDistributionViCRI.module.scss';

const NO_DATA = 'Distribution data is not available for this report';

type LocationType = 'home' | 'destination' | 'home-destination' | undefined;
export type DistributionItemTypeColored = DistributionItemType & {
	fill: string;
	caption: LocationType;
};

export type ChartTypeType = 'HR' | 'VCR';

type VicriColorsType = {
	[key: string]: string;
};

const vicriColors: VicriColorsType = {
	'0': Color.heatmap0,
	'1': Color.heatmap1,
	'2': Color.heatmap2,
	'3': Color.heatmap3,
	'4': Color.heatmap4,
	'5': Color.heatmap5,
	'6': Color.heatmap6,
	'7': Color.heatmap7,
	'8': Color.heatmap8,
	'9': Color.heatmap9,
	'10': Color.heatmap10,
	'11': Color.heatmap11,
	'-1': Color.heatmap12,
};

const getCaption = (isHome: boolean, isDestination: boolean): LocationType => {
	let caption: LocationType;
	if (isHome && isDestination) {
		caption = 'home-destination';
	} else if (isHome) {
		caption = 'home';
	} else if (isDestination) {
		caption = 'destination';
	}
	return caption;
};

const getColoredDataByLocation = (
	barChartData: BarChartDataType,
	defaultColor: string = '#DEE2E5',
	homeColor: string = '#7D8C97',
	destinationColor: string = '#DA222F',
) => {
	const { distribution, homeValue, destinationValue } = barChartData;
	return distribution?.map((item) => {
		const isHome = homeValue >= item.min && homeValue < item.max;
		const isDestination = destinationValue >= item.min && destinationValue < item.max;

		const homeFill = isHome ? homeColor : defaultColor;
		const destinationFill = isDestination ? destinationColor : defaultColor;
		const caption = getCaption(isHome, isDestination);

		return {
			...item,
			fill: destinationFill !== defaultColor ? destinationFill : homeFill,
			caption,
		};
	});
};

const getColoredDataByRate = (barChartData: BarChartDataType, defaultColor: string = '#DEE2E5') => {
	const CONFLICT = 'Conflict';
	const POOR_DATA = 'Poor data';

	const { distribution, homeValue, destinationValue } = barChartData;

	return distribution.map((item) => {
		const isHome = homeValue === item.max;
		const isDestination = destinationValue === item.max;
		const caption = getCaption(isHome, isDestination);

		return {
			...item,
			fill: vicriColors[item.max] || defaultColor,
			name: (item.name === '0' && POOR_DATA) || (item.name === '-1' && CONFLICT) || item.name,
			caption,
		};
	});
};

function getVicriTotalStatistics(barChartData: BarChartDataType) {
	let indexed = 0;
	let conflict = 0;
	let poorData = 0;

	barChartData.distribution.forEach((item) => {
		if (item.min === -1) {
			conflict = item.value;
		} else if (item.min === 0) {
			poorData = item.value;
		} else {
			indexed += item.value;
		}
	});

	return { indexed, conflict, poorData };
}

type StatisticsDistributionViCRIType = {
	distributions: DistributionsType;
};

export default function StatisticsDistributionViCRI(props: StatisticsDistributionViCRIType) {
	const { distributions } = props;
	if (
		!distributions.crimeHRBarChart ||
		!distributions.crimeVCRBarChart ||
		!distributions.crimeIndexBarChart
	)
		return <div className={styles['no-data']}>{NO_DATA}</div>;

	const { crimeHRBarChart, crimeVCRBarChart, crimeIndexBarChart } = distributions;

	const coloredCrimeHRBarChart = getColoredDataByLocation(crimeHRBarChart);
	const coloredCrimeVCRBarChart = getColoredDataByLocation(crimeVCRBarChart);
	const coloredCrimeIndexBarChart = getColoredDataByRate(crimeIndexBarChart);

	const totalVicriStatistics = getVicriTotalStatistics(crimeIndexBarChart);

	const destinationMinHR = +crimeHRBarChart.destinationMinValue.toFixed(1);
	const destinationMaxHR = +crimeHRBarChart.destinationMaxValue.toFixed(1);
	const percentDestinationHR = crimeHRBarChart.lessValueItemsPercentDestination;

	const destinationMinVCR = +crimeVCRBarChart.destinationMinValue.toFixed(0);
	const destinationMaxVCR = +crimeVCRBarChart.destinationMaxValue.toFixed(0);
	const percentDestinationeVCR = crimeVCRBarChart.lessValueItemsPercentDestination;

	// TODO: need to add actual data after update API
	// const home = 'USA';
	// const destination = 'HONDURAS';
	const home = '';
	const destination = '';

	return (
		<div className={styles.distribution}>
			<div className={styles['distribution-rate-block']}>
				<div className={styles['bar-container']}>
					<DistributionBarChart
						data={coloredCrimeHRBarChart}
						showComparison
						percentComparison={percentDestinationHR}
						type="HR"
						destinationMin={destinationMinHR}
						destinationMax={destinationMaxHR}
						title="Estimated Homicide Rate"
						description="Per 100,000 people"
						captionX="HR per 100,000 people"
						captionY="Frequency"
						minTick={5}
					/>
				</div>
				<div className={styles['bar-container']}>
					<DistributionBarChart
						data={coloredCrimeVCRBarChart}
						showComparison
						percentComparison={percentDestinationeVCR}
						type="VCR"
						destinationMin={destinationMinVCR}
						destinationMax={destinationMaxVCR}
						title="Estimated Other Violent Crime Rate (VCR)"
						description="Per 100,000 people"
						captionX="VRC per 100,000 people"
						captionY="Frequency"
						minTick={5}
					/>
				</div>
			</div>
			<div className={styles['bar-container']}>
				<DistributionBarChart
					data={coloredCrimeIndexBarChart}
					totalStatistics={totalVicriStatistics}
					title="ViCRI Index National Rankings Distribution"
					description="Number of Countries by ViCRI Class"
					home={home}
					destination={destination}
				/>
			</div>
		</div>
	);
}
