import { useRef, useState } from 'react';
import { saveAs } from 'file-saver';

import { Download, InfoOutlinedIcon, TooltipWidth220 } from '../../../components';
import cn from '../../../lib/classNames';
import html2canvas from '../../../lib/html2canvas';
import PreloaderSimilarCities from '../../Report/ReportPreloader/PreloaderSimilarCities';
import PreloaderViolentCrimeRiskGraph from '../../Report/ReportPreloader/PreloaderViolentCrimeRiskGraph';
import WidgetVicriRanking from '../WidgetVicriRanking/WidgetVicriRanking';

import RiskRating from './RiskRating';
import SimilarClassRiskCities from './SimilarClassRiskCities';
import { classRiskColor } from './utils';

import styles from './ViolentCrimeRisk.module.scss';

type ViolentCrimeRiskType = {
	title: string;
	subTitle?: string;
	showTooltipIcon: boolean;
	showDownloadScreenIcon: boolean;
	locationHome?: string;
	locationDestination?: string;
	riskValueHome?: number;
	riskValueDestination?: number;
	locationCircumstancesHome?: string[];
	locationCircumstancesDestination?: string[];
	homeCrimeRiskIndexLocations?: string[];
	destinationCrimeRiskIndexLocations?: string[];
	isShowEstimate: boolean;
	isPreloader?: boolean;
};

function ViolentCrimeRisk(props: ViolentCrimeRiskType) {
	const {
		title = 'Violent Crime Risk Rating',
		subTitle = '',
		showTooltipIcon = true,
		showDownloadScreenIcon = true,
		locationHome,
		locationDestination,
		riskValueHome,
		riskValueDestination,
		locationCircumstancesHome,
		locationCircumstancesDestination,
		homeCrimeRiskIndexLocations,
		destinationCrimeRiskIndexLocations,
		isShowEstimate = true,
		isPreloader = false,
	} = props;

	const elementToShare = useRef<HTMLDivElement>(null);

	const [tipActive, setTipActive] = useState(0);

	const handleClickShare = () => {
		html2canvas(elementToShare.current).then((canvas) => {
			saveAs(canvas.toDataURL(), 'violent-crime-risk.png');
		});
	};

	const circumstances = (value: string, location: string) => {
		if (locationCircumstancesHome?.length && value && location === 'home') {
			return locationCircumstancesHome.includes(value);
		}
		if (locationCircumstancesDestination?.length && value && location === 'destination') {
			return locationCircumstancesDestination.includes(value);
		}

		return false;
	};

	const isConflictHome = circumstances('MILITARY_CONFLICT', 'home');
	const isNoDataHome = circumstances('NO_DATA', 'home');
	const isConflictDestination = circumstances('MILITARY_CONFLICT', 'destination');
	const isNoDataDestination = circumstances('NO_DATA', 'destination');
	const classRiskColorHome = classRiskColor(isConflictHome, isNoDataHome, riskValueHome);
	const classRiskColorDestination = classRiskColor(
		isConflictDestination,
		isNoDataDestination,
		riskValueDestination,
	);

	return (
		<div
			ref={elementToShare}
			className={cn(styles['violent-crimer-risk-container'], 'section-wrap-white', {
				[styles['preloader-section']]: isPreloader,
			})}
		>
			{isShowEstimate &&
				!!(isConflictHome || isNoDataHome || isConflictDestination || isNoDataDestination) && (
					<div className={styles['block-info-wrap']}>
						<span className={cn(styles['block-info-icon'], 'material-icons')}>fmd_bad</span>
						<span className="block-info-text">Low confidence estimate</span>
					</div>
				)}

			<div className={styles.header}>
				<div className={styles['block-text']}>
					<h4 className={cn({ [styles['preloader-text-block']]: isPreloader })}>
						{title}
						{!isPreloader && showTooltipIcon && (
							<div className={styles['tip-wrap']}>
								<TooltipWidth220
									id="tooltip-1"
									open={tipActive === 1}
									arrow
									maxWidth={258}
									placement="bottom"
									title={
										<>
											This rating and the ViCRI index represent the most accurate estimation efforts
											available today about the specific risk of serious violent crime at the urban
											level on an international scale (measures homicide, aggravated assault,
											robbery, and rape risks.) Visit{' '}
											<a
												className="tooltip-link-text"
												href="https://violenceriskindex.org/"
												target="_blank"
												rel="noreferrer"
											>
												violenceriskindex.org
											</a>{' '}
											for additional information.
											<p
												className="link tooltip-link"
												onClick={() => {
													setTipActive(0);
												}}
											>
												CLOSE
											</p>
										</>
									}
								>
									<div
										className={styles['block-info-icon']}
										onClick={() => {
											setTipActive(1);
											localStorage.removeItem('tooltipClickCancel');
										}}
									>
										<InfoOutlinedIcon className="icons-animation" fontSize="large" />
									</div>
								</TooltipWidth220>
							</div>
						)}
					</h4>
				</div>
				{showDownloadScreenIcon && (
					<div className="download-icon" onClick={handleClickShare}>
						<Download className="icons-animation" />
					</div>
				)}
			</div>
			<div>
				<span className={cn(styles['sub-line'], { [styles['preloader-text-block']]: isPreloader })}>
					{subTitle}
				</span>
			</div>
			<div className={styles['graph-container']}>
				<div className={cn(styles['graph-wrap'])}>
					<div className={styles['left-box']}>
						<div>
							<div className={cn(styles['similar-cities-content'], styles['similar-cities-home'])}>
								{isPreloader ? (
									<PreloaderSimilarCities />
								) : (
									<>
										<div>
											<RiskRating
												location={locationHome}
												locationTitle="Home"
												riskValue={riskValueHome!}
												isConflict={isConflictHome}
												isNoData={isNoDataHome}
											/>
										</div>
										<SimilarClassRiskCities
											riskValue={riskValueHome}
											crimeRiskIndexLocations={homeCrimeRiskIndexLocations}
											classRiskColor={classRiskColorHome}
											isConflict={isConflictHome}
											isNoData={isNoDataHome}
										/>
									</>
								)}
							</div>
							<div className={cn(styles['similar-cities-content'])}>
								{isPreloader ? (
									<PreloaderSimilarCities />
								) : (
									<>
										<div className="similar-cities-left">
											<RiskRating
												location={locationDestination}
												locationTitle="Destination"
												riskValue={riskValueDestination!}
												isConflict={isConflictDestination}
												isNoData={isNoDataDestination}
											/>
										</div>
										<SimilarClassRiskCities
											riskValue={riskValueDestination}
											crimeRiskIndexLocations={destinationCrimeRiskIndexLocations}
											classRiskColor={classRiskColorDestination}
											isConflict={isConflictDestination}
											isNoData={isNoDataDestination}
										/>
									</>
								)}
							</div>
						</div>
					</div>
					<div className={styles['right-box']}>
						<div className={styles['graph-top']}>
							{isPreloader ? (
								<PreloaderViolentCrimeRiskGraph />
							) : (
								<WidgetVicriRanking
									isShadow={false}
									isHeader={false}
									isFooter={false}
									locationCircumstances={locationCircumstancesDestination}
									crimeIndexLocation={locationDestination}
									crimeIndex={riskValueDestination}
									description={
										isConflictDestination
											? 'This location is rated ‘extremely high risk’ due to ongoing armed violence. Risk data should be considered unreliable.'
											: ''
									}
									theme="theme-light"
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ViolentCrimeRisk;
