import { useRef } from 'react';
import { saveAs } from 'file-saver';

import { adaptDataFromHomeCovidTrendHistoryApi } from '../../../api/adapter';
import { CovidTrendHistoryType } from '../../../api/types/apiGetReport';
import { DataLayer, Download, GraphColor, RiskTrends } from '../../../components';
import cn from '../../../lib/classNames';
import html2canvas from '../../../lib/html2canvas';

import styles from './GeographicRiskTrends.module.scss';

type GeographicRiskTrendsType = {
	showDownloadScreenIcon?: boolean;
	dataLayer?: number;
	casesTrend?: number;
	deathTrend?: number;
	covidTrendHistory?: CovidTrendHistoryType[];
	location?: string;
	headerLabelLocation: 'Residence' | 'Desination';
};

export default function GeographicRiskTrends(props: GeographicRiskTrendsType) {
	const {
		showDownloadScreenIcon = true,
		dataLayer = -1,
		casesTrend = 0,
		deathTrend = 0,
		covidTrendHistory,
		location,
		headerLabelLocation,
	} = props;

	const mmCasesTrend = casesTrend ? Math.round(casesTrend) : 0;
	const mmDeathTrend = deathTrend ? Math.round(deathTrend) : 0;

	const elementToShare = useRef<HTMLDivElement>(null);

	const handleClickShare = () => {
		html2canvas(elementToShare.current).then((canvas) => {
			saveAs(canvas.toDataURL(), 'geo-risk-trends.png');
		});
	};

	const renderData = (data: number) => {
		return data > 0 ? `+${data}` : data;
	};

	return (
		<div
			ref={elementToShare}
			className={cn(
				styles['geographic-risk-trends-container'],
				'section-wrap-white',
				'mob-pd-h-15',
			)}
		>
			{dataLayer >= 0 && (
				<div className="data-layer-container">
					<DataLayer numData={dataLayer} />
				</div>
			)}
			<div className={styles.header}>
				<div className={styles['block-text']}>
					<h4>COVID-19 Geographic Risk Trends</h4>
					<p>
						{headerLabelLocation}: {location}
					</p>
				</div>
				{showDownloadScreenIcon && (
					<div className="download-icon" onClick={handleClickShare}>
						<Download className="icons-animation" />
					</div>
				)}
			</div>
			<div className={styles['box-graph']}>
				<div className={styles['block-graph-left']}>
					<RiskTrends data={adaptDataFromHomeCovidTrendHistoryApi(covidTrendHistory)} />
				</div>
				<div className={styles['block-graph-right']}>
					<div className={styles['graph-color']}>
						<header className={styles['graph-color-header']}>Last 7 days</header>
						<div>
							<div className={styles['data-arrow']}>
								<GraphColor className="graph-color-health" />
								<p className={styles.value}>{renderData(mmCasesTrend)} %</p>
							</div>
							<span className={styles['data-label']}>New cases</span>
						</div>
						<div>
							<div className={styles['data-arrow']}>
								<GraphColor className="graph-color-violet" />
								<p className={styles.value}>{renderData(mmDeathTrend)} %</p>
							</div>
							<span className={styles['data-label']}>New deaths</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
