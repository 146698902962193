import Markdown from 'react-markdown';

import cn from '../../../lib/classNames';

import Accordion from './Accordion';

import styles from './Emergency.module.scss';

type EmergencyType = {
	className?: string;
	data?: string;
};

export default function Emergency(props: EmergencyType) {
	const { className, data = 'N/A' } = props;

	return (
		<section className={cn(styles.emergency, className)}>
			<Accordion description={<Markdown components={{ h2: 'h4' }}>{data}</Markdown>} />
		</section>
	);
}
