import { useRef } from 'react';
import { saveAs } from 'file-saver';
import get from 'get-value';

import { DataLayer, Download, Map, RiskRatingChart } from '../../../components';
import Config from '../../../config';
import cn from '../../../lib/classNames';
import html2canvas from '../../../lib/html2canvas';

import styles from './GeographicRisk.module.scss';

type GeographicRiskType = {
	showDownloadScreenIcon?: boolean;
	headerText: string;
	dataLayer?: number;
	riskValue?: number;
	riskLocation?: string;
	riskLabel?: string;
	location: {
		value: {
			longitude: number;
			latitude: number;
		};
	};
	colors: string;
	headerLabelLocation: string;
};

export default function GeographicRisk(props: GeographicRiskType) {
	const {
		showDownloadScreenIcon = true,
		headerText,
		dataLayer = -1,
		riskValue,
		riskLocation,
		riskLabel,
		location,
		colors,
		headerLabelLocation,
	} = props;

	const mapCenter = [get(location, 'value.longitude'), get(location, 'value.latitude')];

	const elementToShare = useRef<HTMLDivElement>(null);

	const handleClickShare = () => {
		html2canvas(elementToShare.current).then((canvas) => {
			saveAs(canvas.toDataURL(), 'geo-risk-rating.png');
		});
	};

	return (
		<div
			ref={elementToShare}
			className={cn(styles['geographic-risk-container'], 'section-wrap-white')}
		>
			{dataLayer >= 0 && (
				<div className="data-layer-container">
					<DataLayer numData={dataLayer} />
				</div>
			)}

			<div className={cn(styles.header, 'mob-pd-h-15')}>
				<div>
					<h4>{headerText}</h4>
					<p>
						{headerLabelLocation} {riskLocation}
					</p>
				</div>
				{showDownloadScreenIcon ? (
					<div className="download-icon" onClick={handleClickShare}>
						<Download className="icons-animation" />
					</div>
				) : null}
			</div>
			<div className={styles['box-graph']}>
				<div className={cn(styles['graph-top-wrap'], styles['wrap-container'])}>
					<div className={styles['graph-top']}>
						<RiskRatingChart value={riskValue} text={riskLabel} colors={colors} />
						<div className={styles['block-text-wrap']}>
							<span className={styles['block-text-state']}>Low Risk</span>
							<div className={styles['block-text-location']} title={riskLocation}>
								{riskLocation}
							</div>
							<span className={styles['block-text-state']}>High Risk</span>
						</div>
					</div>
				</div>
				<div className={cn(styles['map-wrap'], styles['wrap-container'])}>
					<div className="block-map">
						<Map
							accessToken={Config.mapbox.accessToken}
							mapStyle={Config.mapbox.style}
							center={mapCenter}
							marker={mapCenter}
							zoom={riskLabel === 'National Ranking' ? 3 : 7}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
